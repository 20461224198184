import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTModalCard = _resolveComponent("KTModalCard")!
  const _component_AddCustomerModal = _resolveComponent("AddCustomerModal")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_KTModalCard, {
      title: "Welcome!",
      description: "There are no customers added yet. <br/>Kickstart your CRM by adding a your first customer",
      image: "media/illustrations/sketchy-1/2.png",
      "button-text": "Add Customer",
      "modal-id": "kt_modal_add_customer"
    }),
    _createVNode(_component_AddCustomerModal)
  ], 64))
}